import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {compareFavorite} from 'utils/map';
import useAppScheme, {TGetFavoriteGroupResponse} from './useAppScheme';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';
import {useOnce} from './useOnce';
import usePrevious from './usePrevious';

type TFavoriteItemType = string | number;
type TCheckIsFavoriteParams = {
  stationId?: TFavoriteItemType;
  pkey?: TFavoriteItemType;
  navX?: TFavoriteItemType;
  navY?: TFavoriteItemType;
  favId?: TFavoriteItemType;
};

type TFavoritePoiData = {
  addInfo: string;
  bldNo1: string;
  bldNo2: string;
  cateNickName: string;
  centerLat: string;
  centerLon: string;
  centerX: string;
  centerY: string;
  clsAName: string;
  clsBName: string;
  clsCName: string;
  clsDName: string;
  custName: string;
  dbSeq: string;
  dcdName: string;
  fastEvChargerAvailableCount: number;
  favId: string;
  favMemo: string;
  fixedIndex: number;
  ggPrice: number;
  groupId: string;
  headingForScore: number;
  hhPrice: number;
  highGgPrice: number;
  highHhPrice: number;
  insDatetime: string;
  lcdName: string;
  llPrice: number;
  mcdName: string;
  mlClass: string;
  noorLat: string;
  noorLon: string;
  noorX: string;
  noorY: string;
  normalEvChargerAvailableCount: number;
  pkey: string;
  poiId: string;
  primaryBun: string;
  roadName: string;
  roadScdName: string;
  rpFlag: number;
  scdName: string;
  secondaryBun: string;
  superFastEvChargerAvailableCount: number;
  updDatetime: string;
};

type TFavoriteGroup = {
  poiDataList: TFavoritePoiData[];
  poiGroupList: TPoiGroup[];
};

type TPoiGroup = {
  color: string;
  groupId: string;
  groupType: string;
  insDatetime: string;
  mapDispYn: 'Y' | 'N';
  name: string;
  poiCount: number;
  poiRegDatetime: string;
  updDatetime: string;
};

export type TCommonItem = {
  poiId: string | number;
  pkey: string | number;
  navX: string | number;
  navY: string | number;
};

type TProps = {
  list?: Array<TCommonItem>;
};

const useFavorite = (props?: TProps) => {
  const {personalPlace, userInteraction} = useAppSelector((state) => ({
    personalPlace: state.userInfo.personalPlace,
    userInteraction: state.userInteraction,
  }));
  const [favoriteList, setFavoriteList] = useState<TFavoritePoiData[]>([]);
  const [favoriteGroup, setFavoriteGroup] = useState<TPoiGroup[]>([]);
  const inApp = useAppScheme();

  const refListLength = usePrevious(props?.list?.length || 0);

  const favoriteMap = useMemo(
    () =>
      favoriteList.reduce(
        (obj, v) => ({...obj, [v.poiId]: v, [v.pkey]: v, ...(v.favId && {[v.favId]: v})}),
        {}
      ),
    [favoriteList]
  );

  // 저장 비교용
  const checkIsFavoriteFromList = useCallback(
    (item) => {
      if (favoriteMap[item.pkey] || favoriteMap[item.poiId] || favoriteMap[item.favId]) {
        return true;
      }

      return favoriteList.some((v) => compareFavorite(v, item));
    },
    [favoriteMap, favoriteList]
  );

  const checkIsFavorite = useCallback(
    (data: TCheckIsFavoriteParams) => {
      if (isOverNewFavoriteVersion() && !data.stationId) {
        return checkIsFavoriteFromList(data);
      }

      if (!personalPlace.loaded || !data) {
        return false;
      }

      const {favorites, home, office, favoritesPublic} = personalPlace.data;
      const favoriteItem = [...favorites, ...favoritesPublic, home, office]
        .filter((v) => !!v)
        .find((f) => (f ? compareFavorite(f, data) : false));

      // 이전 비교 로직. 주소쪽 동작 검증 확인 예정
      // return (
      //   v.poiId === poiJson.poiId ||
      //   v.pkey === addressData?.roadPkey ||
      //   v.pkey === addressData?.jibunPkey ||
      //   // v.customName === poiJson.poiName ||
      //   // v.customName === poiJson.address ||
      //   (v.centerX === addressData?.centerX && v.centerY === addressData?.centerY)
      // );

      return !!favoriteItem;
    },
    [personalPlace, checkIsFavoriteFromList]
  );

  // 저장포함 개별 확인용 (실시간)
  const isFavoriteItem = useCallback(
    (data: TCheckIsFavoriteParams): Promise<TGetFavoriteGroupResponse> => {
      return new Promise((resolve, reject) => {
        if (isOverNewFavoriteVersion() && !data.stationId) {
          return inApp
            .getFavoriteState(data)
            .then((d) => resolve(d))
            .catch(reject);
        }

        return resolve({result: checkIsFavorite(data), poiDataList: []});
      });
    },
    [checkIsFavorite, inApp]
  );

  const getFavoriteGroup = useCallback(
    async (data: TCommonItem[]) => {
      if (!isOverNewFavoriteVersion()) {
        return;
      }

      const result = (await inApp.getFavoriteGroup(
        data.map((item) => item.poiId)
      )) as TFavoriteGroup;

      const poiDataList = result?.poiDataList || [];
      const poiGroupList = result?.poiGroupList || [];

      setFavoriteList(poiDataList.map((v) => ({...v, navX: v.noorX, navY: v.noorY})));
      setFavoriteGroup(poiGroupList.sort((a, b) => b.updDatetime.localeCompare(a.updDatetime)));
    },
    [inApp]
  );

  const getFavoriteGroupColor = useCallback(
    (item: TCommonItem) => {
      if (!item || !item?.pkey || !item?.poiId) {
        return null;
      }

      const matchingGroupIds = favoriteList.reduce((arr, v) => {
        if (!compareFavorite(v, item) || !v.groupId) {
          return arr;
        }

        return [...arr, v.groupId];
      }, [] as string[]);

      if (matchingGroupIds.length === 0) {
        return null;
      }

      // favoriteGroup 은 updDatetime 기준 소팅된 상태
      const matchingGroups = favoriteGroup.find((group) =>
        matchingGroupIds.includes(group.groupId)
      );

      return matchingGroups?.color ?? null;
    },
    [favoriteList, favoriteGroup]
  );

  useOnce(
    isOverNewFavoriteVersion() && (props?.list || []).length && favoriteList.length === 0,
    () => {
      if (props?.list) {
        getFavoriteGroup(props.list);
      }
    }
  );

  useEffect(() => {
    const triggered =
      userInteraction.pauseKey ||
      userInteraction.resumeKey ||
      refListLength !== props?.list?.length;

    if (props?.list && triggered) {
      getFavoriteGroup(props.list);
    }
  }, [props?.list, userInteraction.pauseKey, userInteraction.resumeKey]);

  return {
    checkIsFavorite,
    getFavoriteGroup,
    favoriteList,
    checkIsFavoriteFromList,
    getFavoriteGroupColor,
    isFavoriteItem,
  };
};

export default useFavorite;
